import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {


  }

      images = [
        {path: 'https://cdn.shopify.com/s/files/1/1305/2183/products/9092_5_1024x1024.jpg?v=1629803124'},
        {path: 'https://resize.indiatvnews.com/en/resize/newbucket/715_-/2021/12/pjimage-2021-12-06t071853-1638755341.jpg'},
        {path: 'https://thumbs.dreamstime.com/b/small-statue-lord-shiva-ground-red-beads-n-114202367.jpg'},
        {path: 'https://www.learnreligions.com/thmb/Xb1-Q7IH9mz1s8RazpD9dy6ZIPo=/2129x1410/filters:fill(auto,1)/186652580-56a483be5f9b58b7d0d75cf3.jpg'},
      ]



}
